




























































































































































































import { Component, Prop, Ref } from "vue-property-decorator";
import Validation from "@/mixins/validation";
import VesselAutocomplete from "@/components/common/vessel/VesselAutocomplete.vue";
import VesselEnquiryAllocationCard from "@/components/vessel/enquiry/VesselEnquiryAllocationCard.vue";
import VesselEnquiryRequiredCertificateList from "@/components/vessel/enquiry/VesselEnquiryRequiredCertificateList.vue";
import VesselEnquiryCertificateTypeCreateDialog from "@/components/dialogs/VesselEnquiryCertificateTypeCreateDialog.vue";
import NationalityAutocomplete from "@/components/common/country/NationalityAutocomplete.vue";
import CloseVesselEnquiryDialog from "@/components/dialogs/CloseVesselEnquiryDialog.vue";
import {
  CertificateTypeVesselEnquiryModel,
  VesselEnquiryModel,
  VesselModel
} from "@/api/generated";
import Api from "@/api";
import momentUtility from "@/utility/momentUtility";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import { VForm } from "@/types/vForm";
import UserModule from "@/store/userModule";

const userModule = getModule(UserModule);
const snackbarModule = getModule(SnackbarModule);

@Component({
  components: {
    NationalityAutocomplete,
    VesselAutocomplete,
    VesselEnquiryAllocationCard,
    VesselEnquiryRequiredCertificateList,
    VesselEnquiryCertificateTypeCreateDialog,
    CloseVesselEnquiryDialog
  }
})
export default class VesselEnquiryEdit extends Validation {
  @Prop(String) private vesselEnquiryId!: string;
  @Ref() private form!: VForm;

  private openLoading = false;
  private loading = false;
  private certificateCreateDialog = false;
  private closeEnquiryDialog = false;
  private refreshKey = false;
  private vessel: VesselModel = {
    name: "",
    rss: "",
    contactName: "",
    telephoneNumber: "",
    emailAddress: "",
    vesselId: "",
    currentContractStartDate: "",
    currentContractEndDate: "",
    contactPostalAddress: ""
  };

  private enquiry: VesselEnquiryModel = {
    nationalityPreferenceNationality: "",
    billingEmailAddress: ""
  };
  private airportOfEntries: string[] = [
    "Aberdeen",
    "Heathrow",
    "Edinburgh",
    "Glasgow",
    "Manchester",
    "Hartlepool",
    "Durham Tee Side",
    "Leeds",
    "Belfast"
  ];

  private choiceLawJurisdiction: string[] = [
    "England",
    "Wales",
    "Scotland",
    "Northern Ireland"
  ];

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private async created() {
    await this.getVesselEnquiry();
    await this.getVessel();
  }

  private toggleCurrency() {
    this.enquiry.isBritishPounds = !this.enquiry.isBritishPounds;
  }

  private async getVesselEnquiry() {
    if (!this.vesselEnquiryId) {
      return;
    }

    const response = await Api.VesselEnquiryService.apiVesselenquiryVesselEnquiryIdGet(
      this.vesselEnquiryId
    );

    this.enquiry = response.data;

    if (this.enquiry.startDate) {
      this.enquiry.startDate = momentUtility.pickerFormat(
        this.enquiry.startDate
      );
    }

    if (this.enquiry.endDate) {
      this.enquiry.endDate = momentUtility.pickerFormat(this.enquiry.endDate);
    }

    this.refreshKey = !this.refreshKey;
  }

  private async getVessel() {
    if (!this.enquiry.vesselId) {
      return;
    }

    const response = await Api.VesselService.apiVesselVesselIdGet(
      this.enquiry.vesselId
    );

    this.vessel = response.data;
  }

  private async onEditVesselEnquiry() {
    if (this.enquiry.closed) {
      return;
    }

    if (!this.form.validate()) {
      return;
    }

    this.loading = true;
    try {
      await Api.VesselEnquiryService.apiVesselenquiryVesselEnquiryIdPut(
        this.vesselEnquiryId,
        this.enquiry
      );
      snackbarModule.setSnackbarMessage("Vessel enquiry saved");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to update vessel enquiry");
    } finally {
      this.loading = false;
    }

    await this.getVesselEnquiry();
    await this.getVessel();
  }

  private onAddCertificate() {
    this.certificateCreateDialog = true;
  }

  private async onDeleteCertificate(
    certificate: CertificateTypeVesselEnquiryModel
  ) {
    if (!certificate.certificateTypeVesselEnquiryId) {
      return;
    }

    try {
      await Api.CertificateTypeVesselEnquiryService.apiCertificatetypevesselenquiryCertificateTypeVesselEnquiryIdDelete(
        certificate.certificateTypeVesselEnquiryId
      );
      snackbarModule.setSnackbarMessage("Certificate requirement deleted");
    } catch {
      snackbarModule.setSnackbarMessage(
        "Failed to delete certificate requirement"
      );
    } finally {
      await this.getVesselEnquiry();
      await this.getVessel();
    }
  }

  private async jobRoleAdded() {
    this.refreshKey = !this.refreshKey;
    await this.getVesselEnquiry();
    await this.getVessel();
  }

  private async jobRoleDeleted() {
    await this.getVesselEnquiry();
    await this.getVessel();
  }

  private async certificateAdded() {
    this.refreshKey = !this.refreshKey;
    await this.getVesselEnquiry();
    await this.getVessel();
  }

  private async enquiryClosed() {
    this.refreshKey = !this.refreshKey;
    await this.getVesselEnquiry();
    await this.getVessel();
  }

  private onCloseVesselEnquiry() {
    this.closeEnquiryDialog = true;
  }

  private async onOpenVesselEnquiry() {
    this.enquiry.closed = false;
    this.openLoading = true;

    try {
      await Api.VesselEnquiryService.apiVesselenquiryVesselEnquiryIdPut(
        this.vesselEnquiryId,
        this.enquiry
      );
      snackbarModule.setSnackbarMessage("Vessel enquiry re-opened");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to re-open vessel enquiry");
    } finally {
      this.openLoading = false;
    }
  }
}
